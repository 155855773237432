import { create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";
import { produce } from "immer";
import CryptoJS from 'crypto-js';
import { initPopup, PopupInfo, PopupState } from "./initialState";


export const usePopupStore = create<PopupState>() (
  persist<PopupState>(
    (set, get) => ({
      popup: CryptoJS.AES.encrypt(JSON.stringify(initPopup), process.env.NEXT_PUBLIC_HASH_CODE!).toString(),
      updatePopup: (popup: PopupInfo) => {
        set(
          produce((draft: PopupState) => {
            const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(popup), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
            draft.popup = encryptedUser;
          })
        )
      },
      getPopup: () => {
        const state = get();

        if (state.popup.constructor === ({}).constructor) {
          set(
            produce((draft: PopupState) => {
              const encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(state.popup), process.env.NEXT_PUBLIC_HASH_CODE!).toString();
              draft.popup = encryptedUser;
            })
          )

          return state.popup;
        } else if (state.popup.constructor === "text".constructor) {
          try {
            return JSON.parse(CryptoJS.AES.decrypt(state.popup, process.env.NEXT_PUBLIC_HASH_CODE!).toString(CryptoJS.enc.Utf8));
          } catch (error) {
            return initPopup;
          }
        } else {
          return initPopup;
        }
      }
    }), {
      name: "popup"
    } as PersistOptions<PopupState>
  )
)
