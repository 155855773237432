import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import { ChooseGiftProps } from './constants';
import { useEffect, useState } from 'react';
import Attribute from '../Product/Attribute';
import { useAttributesStore } from '@/stores/useAttributesStore';
import { CustomVariantItemProps } from '../Product/constants';


const ChooseGift = (props: ChooseGiftProps) => {
  const { open, setOpen, image, name, variant, setVariant, update_gift } = props;
  const { getAttributes } = useAttributesStore();
  const attributesData = getAttributes();
  const [displayImage, setDisplayImage] = useState(image);
  const [selectedCustomVariant, setSelectedCustomVariant] = useState<CustomVariantItemProps | null>(null);
  const [fromName, setFromName] = useState<string>('');
  const [toName, setToName] = useState<string>('');
  const [toEmail, setToEmail] = useState<string>('');
  const [toMobile, setToMobile] = useState<string>('');
  const [toMessage, setToMessage] = useState<string>('');
  const [pregnancy, setPregnancy] = useState<string>('');
  const [duDate, setDuDate] = useState<Date | null>(null);
  const [childName, setChildName] = useState<string>('');
  const [childAge, setChildAge] = useState<number | null>(null);
  const [childGender, setChildGender] = useState<string>('');

  useEffect(() => {
    if (variant !== null && variant !== undefined) {
      setDisplayImage(variant.cart_image);
    }
  }, [variant])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 z-[60]" />
        <Dialog.Content
          className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 md:min-w-[400px] mx-auto z-[65] h-fit max-h-[70%] md:max-h-1/2 outline-none focus:ring-transparent"
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          <Dialog.Title />
          <Dialog.Description />
          <div className="p-8 bg-white rounded-t-lg md:hidden">
            <div className="flex items-start space-x-4">
              <Image
                src={displayImage}
                width={800}
                height={800}
                alt={name + " gallery"}
                quality={100}
                className="w-[90px] aspect-square object-contain"
              />
              <div className="">
                <div className="text-xs font-semibold leading-6 text-attributeTitle font-poppins">{name}</div>
              </div>
            </div>
            <div className="overflow-y-auto add-to-cart-attribute-height">
              <Attribute
                product_code={props.product_id.toString()}
                attribute_value_list={props.attribute_value_list}
                product_variant_list={props.product_variant_list}
                attributes={attributesData.data}
                style="popup"
                variant={variant}
                setVariant={setVariant}
                virtual_product={props.virtual_product}
                need_shipping={props.need_shipping}
                custom_variant_list={[]}
                selectedCustomVariant={selectedCustomVariant}
                setSelectedCustomVariant={setSelectedCustomVariant}
                fromName={fromName}
                setFromName={setFromName}
                toName={toName}
                setToName={setToName}
                toEmail={toEmail}
                setToEmail={setToEmail}
                toMobile={toMobile}
                setToMobile={setToMobile}
                toMessage={toMessage}
                setToMessage={setToMessage}
                pregnancy={pregnancy}
                setPregnancy={setPregnancy}
                duDate={duDate}
                setDuDate={setDuDate}
                childName={childName}
                setChildName={setChildName}
                childAge={childAge}
                setChildAge={setChildAge}
                childGender={childGender}
                setChildGender={setChildGender}
              />
              {variant !== null && variant !== undefined && variant.qty === 0 && (
                <div className="my-4 text-sm italic leading-9 text-promotionPrice font-poppins">Stok produk tidak tersedia</div>
              )}
              <div className="mt-6 text-sm font-normal text-black capitalize md:text-xs font-poppins">Jumlah : {props.qty}</div>
            </div>
            <div
              className={`w-full p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-base font-poppins font-normal mt-6 rounded-lg`}
              onClick={() => variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0) ? "" : update_gift}
            >
              Simpan
            </div>
          </div>
          <div className="hidden w-full p-8 bg-white rounded-lg md:block h-fit">
            <div className="flex justify-end mb-4">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
                alt="close icon"
                width={12}
                height={12}
                quality={100}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <div className="grid grid-cols-3 gap-8 h-fit">
              <div className="col-span-1">
                <Image
                  src={displayImage}
                  width={800}
                  height={800}
                  alt={name + " gallery"}
                  quality={100}
                  className="object-contain w-full aspect-square"
                />
              </div>
              <div className="col-span-2">
                <div className="mt-1 text-xs font-bold text-black font-poppins">{name}</div>
                <hr className="mt-4 bg-reviewBg" />
                <div className="h-[200px] overflow-y-auto">
                  <Attribute
                    product_code={props.product_id.toString()}
                    attribute_value_list={props.attribute_value_list}
                    product_variant_list={props.product_variant_list}
                    attributes={attributesData.data}
                    style="popup"
                    variant={variant}
                    setVariant={setVariant}
                    virtual_product={props.virtual_product}
                    need_shipping={props.need_shipping}
                    custom_variant_list={[]}
                    selectedCustomVariant={selectedCustomVariant}
                    setSelectedCustomVariant={setSelectedCustomVariant}
                    fromName={fromName}
                    setFromName={setFromName}
                    toName={toName}
                    setToName={setToName}
                    toEmail={toEmail}
                    setToEmail={setToEmail}
                    toMobile={toMobile}
                    setToMobile={setToMobile}
                    toMessage={toMessage}
                    setToMessage={setToMessage}
                    pregnancy={pregnancy}
                    setPregnancy={setPregnancy}
                    duDate={duDate}
                    setDuDate={setDuDate}
                    childName={childName}
                    setChildName={setChildName}
                    childAge={childAge}
                    setChildAge={setChildAge}
                    childGender={childGender}
                    setChildGender={setChildGender}
                  />
                  {variant !== null && variant !== undefined && variant.qty === 0 && (
                    <div className="my-4 text-sm italic leading-9 text-promotionPrice font-poppins">Stok produk tidak tersedia</div>
                  )}
                </div>
              </div>
            </div>
            <hr className="w-full my-6 bg-reviewBg" />
            <div className="flex justify-end">
              <div
                className={`w-[185px] p-2 ${variant === null || variant === undefined || (variant !== null && variant !== undefined && variant.qty === 0) ? "bg-originPrice" : "bg-review cursor-pointer"} text-center text-white text-sm font-poppins font-normal rounded-lg`}
                onClick={update_gift}
              >
                Simpan
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default ChooseGift
